import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eEventTypes from 'Engine/enums/eBaseEventTypes';
import { makeHitArea } from 'Engine/utils/makeHitArea';
import { getRandom } from 'Engine/utils/getRandom';
import FlashLib from 'flashlib_onlyplay';
import StatesManager from 'Engine/base/states/StatesManager';
import { Spine } from 'pixi-spine';
import animationTypes from '../enums/animationTypes';

export default class BaseGameUiContainer extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.init();
    this.addListeners();
    this.addActions();
  }

  init() {
    this.purchaseButton = this.getChildByName('purchaseButton');
    makeHitArea(this.purchaseButton);
    this.purchaseButton.enabled = !EntryPoint.GameModel.isFreeSpinsMode;
    this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
    this.purchaseButtonAnimationContainer = this.getChildByName('purchaseButtonAnimation');
    if (window.OPWrapperService.config.disableBuyBonus) this.removeChild(this.purchaseButton, this.purchaseButtonAnimationContainer);
  }

  addListeners() {
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onGameResized(data) {
    this.stopPurchaseAnimation();
    if ( data.isLandscape) {
      this.goToFrame(2);
    } else {
      this.goToFrame(1);
    }
    this.init();
    this.addActions();
    this.onStateChanged({ params: StatesManager.currentState.stateData.name });

  }

  initPurchaseAnimation() {
    const spineResource = PIXI.Assets.get(animationTypes.PURCHASE_BUTTON);
    if (spineResource) {
      this.purchaseButtonAnimation = new Spine(spineResource.spineData);
      this.purchaseButtonAnimation.state.addListener({
        complete: this.loopPurchaseAnimation.bind(this)
      });
      this.purchaseButtonAnimationContainer.addChild(this.purchaseButtonAnimation);
    }
  }
  loopPurchaseAnimation() {
    if (!this.purchaseButtonAnimation) {
      this.initPurchaseAnimation();
    }

    this.purchaseButtonAnimation.visible = false;
    let delay = getRandom(2000, 10000);

    this.purchaseAnimationTimeout = setTimeout(() => {
      this.purchaseButtonAnimation.visible = true;
      this.purchaseButtonAnimation.state.setAnimation(0, 'animation', false);
    }, delay);
  }

  stopPurchaseAnimation() {
    if (this.purchaseAnimationTimeout) {
      clearTimeout(this.purchaseAnimationTimeout);
      this.purchaseAnimationTimeout = null;
    }

    if (this.purchaseButtonAnimation) {
      this.purchaseButtonAnimation.destroy();
      this.purchaseButtonAnimation = null;
    }
  }

  addActions() {
    this.purchaseButton.actions = {
      click: {
        callback: this.onPurchaseClick, context: this
      },
      touchend: {
        callback: this.onPurchaseClick, context: this
      }
    }
  }

  onPurchaseClick() {
    GlobalDispatcher.dispatch(eEventTypes.EBET_PURCHASE_BTN_CLICK);
  }

  onStateChanged(event) {
    switch (event.params) {
      case 'showStartScreenState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
        this.stopPurchaseAnimation();
        break;

      case 'waitUserInteractionState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = false;
        this.stopPurchaseAnimation();
        break;

      case 'idleState':
        this.purchaseButton.enabled = !EntryPoint.GameModel.isFreeSpinsMode;
        this.purchaseButton.visible = !EntryPoint.GameModel.isFreeSpinsMode;
        this.loopPurchaseAnimation();
        break;

      case 'requestRespinRollingState':
      case 'requestRollingState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case 'minimalRollingState':
      case 'minimalRespinRollingState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case 'stopRollingState':
      case 'stopRespinRollingState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case 'expandSymbolsState':
      case 'showWinningsState':
      case 'showExpandedWinState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case 'requestBonusState':
        this.purchaseButton.enabled = false;
        this.stopPurchaseAnimation();
        break;

      case 'freeSpinsAddState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = false;
        this.stopPurchaseAnimation();
        break;

      case 'freeSpinsOutState':
        this.purchaseButton.enabled = false;
        this.purchaseButton.visible = true;
        this.stopPurchaseAnimation();
        break;

      default:
        this.purchaseButton.enabled = false;
        break;
    }

    if (OPWrapperService.freeBetsController.isActive) {
      this.stopPurchaseAnimation();
      this.purchaseButton.enabled = false;
      this.purchaseButton.visible = false;
    }
  }
}

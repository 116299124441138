import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';
import EntryPoint from 'Engine/EntryPoint';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import SoundManager from 'Engine/soundManager/SoundManager';
import { gsap } from 'gsap';
import eBaseEventTypes from 'Engine/enums/eBaseEventTypes';

export default class ControllerAntisipators extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);
    this._animations = [];
    this._spacialSymbolCount = [0];
    this._totalInvolvedReelsCount = 0;
    this._involvedReels = [];
    this._activatedSymbolIds = [];

    this._showDuration = 0.2;
    this._hideDuration = 0.25;
    this._minCountForStartAnimation = [2];
    this._maxCountForStartAnimation = [2];
    this._startsFromReelIndex = undefined;
    this._endsOnReelIndex = undefined;
    this._mainSoundType = '';
    this._mainSoundVolume = 0.6;
    this._mainSoundGroup = 'winning';
    this._tileSoundType = '';
    this._tileSoundVolume = 0.3;
    this._tileSoundGroup = 'winning';
    this._animationType = '';
    this._animationName = '';
    this._triggerSymbolsId = []; // number || [number] || [[number, number]]
    this._turnOnIdsOnFreespins = [];
  }

  get involvedReels() {
    return this._involvedReels;
  }

  set animationType(value) {
    this._animationType = value
  }

  set animationName(value) {
    this._animationName = value
  }

  set triggerSymbolsId(value) { // number || [number, number] || [number, [number, number]]
    if (!Array.isArray(value)) {
      value = [value];
    }

    this._triggerSymbolsId = value.map(id => Array.isArray(id) ? id : [id])
    this._spacialSymbolCount = new Array(value.length).fill(0);
  }

  set mainSoundType(value) {
    this._mainSoundType = value
  }

  set mainSoundVolume(value) {
    this._mainSoundVolume = value;
  }

  set mainSoundGroup(value) {
    this._mainSoundGroup = value;
  }

  set tileSoundType(value) {
    this._tileSoundType = value
  }

  set tileSoundVolume(value) {
    this._tileSoundVolume = value;
  }

  set tileSoundGroup(value) {
    this._tileSoundGroup = value;
  }

  set minCountForStartAnimation(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    this._minCountForStartAnimation = value;
  }

  set maxCountForStartAnimation(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    this._maxCountForStartAnimation = value;
  }

  set startsFromReelIndex(index) {
    this._startsFromReelIndex = index;
  }

  set endsOnReelIndex(index) {
    this._endsOnReelIndex = index;
  }

  get startsFromReelIndex() {
    return this._startsFromReelIndex === undefined ? Math.min(...this._minCountForStartAnimation) : this._startsFromReelIndex;
  }

  get endsOnReelIndex() {
    return this._endsOnReelIndex === undefined ? this.children.length - 1 : this._endsOnReelIndex;
  }

  get showDuration() {
    return this._showDuration;
  }

  set showDuration(value) {
    this._showDuration = value
  }

  get hideDuration() {
    return this._hideDuration;
  }

  set hideDuration(value) {
    this._hideDuration = value
  }

  get activatedSymbolIds() {
    return this._activatedSymbolIds;
  }

  set turnOnIdsOnFreespins(value) {
    if (!Array.isArray(value)) {
      value = [value];
    }

    this._turnOnIdsOnFreespins = value;
  }

  get turnOnIdsOnFreespins() {
    return this._turnOnIdsOnFreespins;
  }

  init() {
    this._initAnimations();
  }

  checkAntisipators(data) {
    this.reset();
    data.forEach((reelData, reelId) => {
      this._triggerSymbolsId
        .filter(triggerIds => !EntryPoint.GameModel.freespinsEnabled || triggerIds.every((id) => this._turnOnIdsOnFreespins.includes(id)) )
        .forEach((triggerIds, index) => {
        if (this._spacialSymbolCount[index] >= this._minCountForStartAnimation[index]
          && this._spacialSymbolCount[index] <= this._maxCountForStartAnimation[index]
          && reelId >= this.startsFromReelIndex && reelId <= this.endsOnReelIndex) {
          if (!this._involvedReels.includes(reelId)) this._involvedReels.push(reelId);
          triggerIds.forEach(id => {
            if (!this._activatedSymbolIds.includes(id)) this._activatedSymbolIds.push(id);
          });
          this.isAntisipator = true;
        }
        for (let symbolId of reelData) {
          if (triggerIds.includes(symbolId)) this._spacialSymbolCount[index]++;
        }
      });
    });

    this._totalInvolvedReelsCount = this._involvedReels.length;
  }

  tryStartAntisipatorForReel(reelId) {
    if (!this.isAntisipator || !this._involvedReels.includes(reelId) || !this._animations[reelId]) return;
    this._animations[reelId].visible = true;
    gsap.to(this._animations[reelId], { alpha: 1, duration: this._showDuration });
    if (this._totalInvolvedReelsCount === this._involvedReels.length) {
      GlobalDispatcher.dispatch(eBaseEventTypes.EBET_ANTISIPATOR__START, reelId);
      this._mainSoundType && SoundManager.play(this._mainSoundType, this._mainSoundVolume, false, this._mainSoundGroup);
    }
  }

  tryStopAntisipatorOnReel(reelId) {
    if (!this.isAntisipator || !this._involvedReels.includes(reelId) || !this._animations[reelId]) return;

    gsap.to(this._animations[reelId], {
      alpha: 0,
      duration: this._hideDuration,
      onComplete: () => this._animations[reelId].visible = false
    });

    this._involvedReels = this._involvedReels.filter(id => id !== reelId);

    if (!this._involvedReels.length) {
      GlobalDispatcher.dispatch(eBaseEventTypes.EBET_ANTISIPATOR__STOP, reelId);
      this._mainSoundType && SoundManager.stop(this._mainSoundType);
      this._tileSoundType && SoundManager.play(this._tileSoundType, this._tileSoundVolume, false, this._tileSoundGroup);
      this.reset();
    }
  }

  async _initAnimations() {
    for await (let [index, container] of this.children.entries()) {
      if (index < this.startsFromReelIndex || index > this.endsOnReelIndex) {
        this._animations.push(undefined);
        continue;
      }

      const animation = await animationCreator.createAnimationWithDelayedLoading(this._animationType);
      await animationCreator.uploadToGPU(animation);
      animation.state.setAnimation(0, this._animationName, true);
      animation.alpha = 0;
      animation.visible = false;
      container.addChild(animation);
      this._animations.push(animation);
    }
  }

  reset() {
    this.isAntisipator = false;
    this._spacialSymbolCount.fill(0);
    this._totalInvolvedReelsCount = 0;
    this._involvedReels = [];
    this._activatedSymbolIds = [];
  }
}

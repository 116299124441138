import FlashLib from 'flashlib_onlyplay';
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import animationCreator from 'Engine/animations/animationCreator';
import animationTypes from '../enums/animationTypes';

export default class LogoContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.addListeners();
    this.active = false;
    this.currentMultiplier = null;
    this.updateMultiplier = this.updateMultiplier.bind(this);
  }

  init() {
    this.animationContainer = this.getChildByName('animationContainer');
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  async initMultiplierAnimation() {
    await PIXI.Assets.load('multiplier');
    this.animation = animationCreator.createAnimation(animationTypes.MULTIPLIER);
    this.animation.state.setAnimation(0, 'animation', false);
    this.animationContainer.addChild(this.animation);
  }

  destroyAnimation() {
    if (this.animation) {
      this.animation.destroy({ children: true });
      this.animation = null;
    }
  }

  updateMultiplier() {
    this.multiplierLabel = this.getChildByName('multiplierLabel');
    this.multiplierLabel.text = `×${EntryPoint.GameModel.freeSpins.multiplier}`;

    if (this.currentMultiplier !== EntryPoint.GameModel.freeSpins.multiplier && this.animation) {
      this.animation.state.setAnimation(0, 'animation', false);
    }

    this.currentMultiplier = EntryPoint.GameModel.freeSpins.multiplier;
  }

  showMultiplier() {
    if (this.active) return;

    // if (EntryPoint.mobile) {
      if (this.currentFrameIndex === 1) {
        this.goToFrame(2);
      }

      if (this.currentFrameIndex === 3) {
        this.goToFrame(4);
        this.initMultiplierAnimation().then(()=> {
          this.animation.scale.set(0.76);
        });
      }
    // } else {
    //   this.goToFrame(2);
    //   this.initMultiplierAnimation().then(()=> {
    //     this.animation.scale.set(1);
    //   });
    // }

    this.updateMultiplier();
    this.enableListening();
    this.active = true;
  }

  hideMultiplier() {
    if (!this.active) return;

    // if (EntryPoint.mobile) {
      if (this.currentFrameIndex === 2) {
        this.goToFrame(1);
      }

      if (this.currentFrameIndex === 4) {
        this.goToFrame(3);
      }
    // } else {
    //   this.goToFrame(1);
    // }

    this.destroyAnimation();
    this.disableListening();
    this.active = false;
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  enableListening() {
    GlobalDispatcher.add('reelsContainer:reelsStopped', this.updateMultiplier);
  }

  disableListening() {
    GlobalDispatcher.remove('reelsContainer:reelsStopped', this.updateMultiplier);
  }

  onGameResized(data) {
    // if (!EntryPoint.mobile) return;

    if (EntryPoint.GameModel.isFreeSpinsMode) {

      if ((this.currentFrameIndex === 3 || this.currentFrameIndex === 4) && EntryPoint.mobile && data.orientation === 'V') {
        this.destroyAnimation();
        this.goToFrame(2);
        this.updateMultiplier();
      } else if ((this.currentFrameIndex === 1 || this.currentFrameIndex === 2) && data.orientation === 'H') {
        this.goToFrame(4);
        this.destroyAnimation();
        this.initMultiplierAnimation().then(()=> {
          this.animation.scale.set(0.76);
        });
        this.updateMultiplier();
      }

    } else {

      if ((this.currentFrameIndex === 3 || this.currentFrameIndex === 4) && EntryPoint.mobile && data.orientation === 'V') {
        this.goToFrame(1);
      } else if ((this.currentFrameIndex === 1 || this.currentFrameIndex === 2) && data.orientation === 'H') {
        this.goToFrame(3);
      }
    }
  }

}

import EntryPoint from 'Engine/EntryPoint';
import SymbolAnimation from '../symbols/SymbolAnimation';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eSymbolIndex from '../enums/eSymbolIndex';
import FlashLib from 'flashlib_onlyplay';

export default class SymbolsAnimationsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.relations = {
      [eSymbolIndex.ESI_WILD_BOOK]: 'book_opening',
      [eSymbolIndex.ESI_INGOT]: 'ingot',
      [eSymbolIndex.ESI_HORUS]: 'horus',
      [eSymbolIndex.ESI_ANUBIS]: 'anubis',
      [eSymbolIndex.ESI_BASTET]: 'bastet',
      [eSymbolIndex.ESI_A]: 'a_royal',
      [eSymbolIndex.ESI_K]: 'k_royal',
      [eSymbolIndex.ESI_Q]: 'q_royal',
      [eSymbolIndex.ESI_J]: 'j_royal',
      [eSymbolIndex.ESI_10]: '10_royal',
    };

    this.init();
    this.addListeners();
  }

  init() {
    this.symbolAnimations = {};
    let configData = EntryPoint.configData;
    let reelsConfig = configData.reels;
    for (let i = 0; i < reelsConfig.reelsCount; i++) {
      for (let j = 0; j < reelsConfig.symbolsInReel; j++) {
        this[`place_${i}_${j}`] = this.getChildByName(`place_${i}_${j}`);
        const symbolAnimation = new SymbolAnimation(null, [i, j]);
        symbolAnimation.on('animationComplete', this.onAnimationComplete.bind(this));
        this.symbolAnimations[`${i}_${j}`] = { reel: i, position: j, animation: symbolAnimation };
        this[`place_${i}_${j}`].addChild(symbolAnimation);
      }
    }
  }

  addListeners() {
    GlobalDispatcher.add('reel:stopped', this.onReelStopped, this);
    GlobalDispatcher.add('winningsController:slowWin', this.showWinningSymbols, this);
    GlobalDispatcher.add('stateStart:showExpandedWinState', this.onShowExpandedWinStateStart, this);
  }

  showWinningSymbols(data) {
    this.removeAnimations();
    data.params.path.forEach((pathData) => {
      if (EntryPoint.GameModel.freeSpinsInActiveState &&
        EntryPoint.GameModel.freeSpins.stickyPositions &&
        EntryPoint.GameModel.freeSpins.stickyPositions.find(pos => pos[0] === pathData[0] && pos[1] === pathData[1])) {
        return;
      }

      this.addAnimation(pathData);
    });
  }

  onReelStopped(event) {
    let reelIndex = event.params;
    let reelData = EntryPoint.GameModel.reels[reelIndex];
    reelData.forEach((symbolId, index) => {
      if (EntryPoint.GameModel.freeSpinsInActiveState) return;

      if (symbolId === 1) {
        this.addAnimation([reelIndex, index], 'book_idle', '');
      }
    });
  }

  onShowExpandedWinStateStart() {
    let animationName = this.relations[EntryPoint.GameModel.specialData.symbol];
    EntryPoint.GameModel.specialData.positions.forEach((path) => {
      for (let i = 0; i < 3; i++) {
        this.addAnimation([path[0], i], animationName, '', EntryPoint.GameModel.specialData.symbol, true);
        this[`place_${path[0]}_${i}`].goToFrame(2);
      }
    });
  }

  addAnimation(pathData, animationName, type = '', symbolId = null, loop = undefined) {
    let id = symbolId || EntryPoint.GameModel.reels[pathData[0]][pathData[1]];
    if (!animationName) {
      animationName = this.relations[id];
    }
    const key = `${pathData[0]}_${pathData[1]}`;
    let symbolAnimation = this.symbolAnimations[key];
    symbolAnimation.animation.start(animationName, loop);
  }

  onAnimationComplete(animation) {
    GlobalDispatcher.dispatch('symbolAnimation:complete', animation.symbolPosition);
  }

  removeAnimations() {
    Object.values(this.symbolAnimations).forEach((data, index, arr) => {
      if (data.animation) {
        data.animation.stop();
      }
      this[`place_${data.reel}_${data.position}`].goToFrame(1);
    });
  }
}

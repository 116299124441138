import FlashLib from 'flashlib_onlyplay';
import animationCreator from 'Engine/animations/animationCreator';

export default class BackgroundContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.init();
    this.animation = null;
    this.active = false;
    this.addListeners();
  }

  init() {
    this.freeSpinsBackground = this.getChildByName('freeSpinsBackground');
    this.mainBackground = this.getChildByName('mainBackground');
    this.mainBackground = !this.active;
    this.freeSpinsBackground.visible = this.active;
  }

  showFreeSpinsBack() {
    this.active = true;
    this.freeSpinsBackground.visible = this.active;
    this.mainBackground = !this.active;

    if (!PIXI.isMobile.any) this.initAnimation();
  }

  onGameResized(data) {
    const isMobile = PIXI.isMobile.any;
    const newFrameIndex = !isMobile || data.isLandscape ? 2 : 1;

    if (newFrameIndex !== this.currentFrameIndex) {
      this._setFrameIndexAndInitGameObjects(newFrameIndex);
    }
  }

  addListeners() {
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  initAnimation() {
    this.animationContainer = this.freeSpinsBackground.getChildByName('animationContainer');

    this.animation = animationCreator.createAnimation('sun');
    this.animation.state.setAnimation(0, 'sun_idle', true);
    this.animationContainer.addChild(this.animation);
  }

  hideFreeSpinsBack() {
    this.active = false;
    this.freeSpinsBackground.visible = this.active;
    this.mainBackground = !this.active;

    if (this.animation) {
      this.animation.destroy({ children: true });
      this.animation = null;
    }
  }

  _setFrameIndexAndInitGameObjects(frameIndex) {
    this.goToFrame(frameIndex);
    this.init();
  }
}

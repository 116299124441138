import BaseState from "Engine/base/states/BaseState";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import { initUi } from '../ui/initUi';

export default class InitState extends BaseState {
    addListeners() {
        super.addListeners();
        GlobalDispatcher.add('game:gameStarted', this.onGameStarted, this);
    }

    async onGameStarted() {
        if (EntryPoint.GameModel.isFreeSpinsMode && (!PIXI.Assets.cache.has('multiplier') || !PIXI.Assets.cache.has('sun'))) {
            window.OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load('multiplier');
            await PIXI.Assets.load('sun');
            window.OPPreloader.hideDelayedPreloader();
        }
        this.complete();
    }

    onEnd() {
        super.onEnd();
        if (!EntryPoint.GameSettings.introScreen) {
            window.OPWrapperService.freeBetsController.show();
            initUi();
        }
    }
}

import EntryPoint from 'Engine/EntryPoint';

import gameConfig from '../../assets/config';
import starTexture from '../../assets/images/star_particle_fred_small.png';
import dotTexture from '../../assets/images/dot.png';

import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import InterExtraBold from '../../assets/fonts/inter/Inter-ExtraBold.woff2';
import InterSemiBold from '../../assets/fonts/inter/Inter-SemiBold.woff2';

// import mobileAssets from '../../assets/exported/gameMobile/FlashLibAssets.json';
import flashlib from '../../assets/exported/gameMobile/FlashLib.json';
import { soundsPreloaderConfig } from '../enums/eSounds';
import Config from '../libs/op-wrapper-service/scaleManager/Config';

import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import animationTypes from '../enums/animationTypes';

const scale = getAssetsScale({
  availableScales: ['0.7', '0.75', '0.8', '0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
})

const assets = `flashlib/gameMobile/AssetsCombined@${scale}x.json`;

export default {
  assets: [
    { name: 'GameAssets', path: assets },
    { name: 'GameFlashLib', path: flashlib },
    { name: 'gameConfig', path: gameConfig },
    { name: animationTypes.SYMBOLS, path: 'animations/symbols/b_o_e_pro_opt.json' },
    { name: animationTypes.LINES_SPINE, path: 'animations/winlines/winlines_pro.json' },
    { name: animationTypes.FREE_SPINS_WINDOW, path: 'animations/freeSpinsBook/freeespins_book.json' },
    { name: animationTypes.PURCHASE_BUTTON, path: 'animations/purchase/bonus_purchase_button/button_bones_pro.json' },
    { name: 'boe-font', path: 'fonts/boe-font/BOE-font.xml' },
    { name: 'starsParticle', path: starTexture },
    { name: 'dotParticle', path: dotTexture },
  ],
  delayedAssets: [
    { name: animationTypes.ANTISIPATOR, path: 'animations/antisipator/skeleton.json' },
    { name: animationTypes.BIG_WIN, path: 'animations/bigWins/DWB_b_o_e.json' },
    { name: animationTypes.COINS, path: 'animations/coins/skeleton.json' },
    { name: animationTypes.MULTIPLIER, path: 'animations/multiplier/skeleton.json' },
    { name: animationTypes.SUN, path: 'animations/sun_freespins/skeleton.json' },
    { name: animationTypes.PURCHASE, path: 'animations/purchase/bonus_purchase/purchase_bonuses.json' },
  ],
  fonts: [
    {
      name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'InterExtraBold',
      path: InterExtraBold,
    },
    {
      name: 'InterSemiBold',
      path: InterSemiBold,
    },
  ],
  sounds: soundsPreloaderConfig,
}

import FlashLib from "flashlib_onlyplay";
import GlobalDispatcher from "Engine/events/GlobalDispatcher";
import EntryPoint from "Engine/EntryPoint";
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';

export default class LineWinningContainer extends FlashLib.MovieClip {
    constructor(data, displayData) {
        super(data, displayData);

        this.isShown = false;
        this.valueText = null;
        this.startY = 10;
        this.offsetY = EntryPoint.configData.symbols.height + EntryPoint.configData.reels.symbolTopOffset;

        this.init();
        this.addListeners();
        this.hide();
    }

    init() {
        this.valueText = this.getChildByName('valueText');
    }

    addListeners() {
        GlobalDispatcher.add('winningsController:slowWin', this.onShowWin, this);
        GlobalDispatcher.add('stateStart:showExpandedWinState', this.onShowExpandedWinStateStart, this);
    }

    onShowWin(event) {
        if (event.params.win === 0) {
            this.visible = !this.isShown;
        }
        this.visible = this.isShown;
        this.valueText.text = MoneyFormat.format(event.params.win, true);
        if (event.params.hasOwnProperty('line')) {
            let lineData = EntryPoint.configData.payLines[event.params.line - 1];
            this.y = this.startY + (lineData[2] * this.offsetY);
        } else {
            this.y = this.startY + this.offsetY;
        }
    }

    onShowExpandedWinStateStart() {
        this.valueText.text = MoneyFormat.format(EntryPoint.GameModel.specialData.win, true);
        this.y = this.startY + this.offsetY * 2;
        this.show();
    }

    show() {
        this.isShown = true;
        this.visible = true;
    }

    hide() {
        this.isShown = false;
        this.visible = false;
    }
}

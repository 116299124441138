import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import SoundManager from 'Engine/soundManager/SoundManager';
import NearWinCalculator from './NearWinCalculator';
import EntryPoint from 'Engine/EntryPoint';
import './style.css';
import { eSounds } from './enums/eSounds';

export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);

    this.reelsContainer = null;
    this.linesContainer = null;
    this.uiContainer = null;
    this.winningContainer = null;
    this.currentOrientation = null;

    this.init();
    this.addListeners();
  }

  onGameResized(data) {
    this.currentOrientation = data.orientation;
    if (EntryPoint.mobile && this.currentOrientation === 'V') {
      // this.slot_container.pivot.x = 0;
      this.slot_container.x = this.slot_container.displayData.x - 3;
      this.slot_container.y = this.slot_container.displayData.y - 35;
      // this.slot_container.scale.set(this.slot_container.displayData.scaleX);
      this.slot_container.scale.set(0.726);
      this.logoTop.visible = true;
      this.logoTop.goToFrame(1);
    } else {
      // this.slot_container.pivot.x = this.slot_container.displayData.width / 2;
      // this.slot_container.pivot.y = this.slot_container.displayData.height / 2;
      this.slot_container.x = this.slot_container.displayData.x - this.slot_container.displayData.width * 0.26;
      this.slot_container.y = this.slot_container.displayData.y - this.slot_container.displayData.height * 0.135;
      this.slot_container.scale.set(1);

      if (!EntryPoint.GameModel.isFreeSpinsMode) {
        this.logoTop.visible = true;
        this.logoTop.goToFrame(2);
      } else {
        this.logoTop.visible = false;
      }
    }
  }

  init() {
    this.winningsController = new BaseSlotWinningsController();

    this.slot_container = this.getChildByName('slot_container');
    this.logo = this.slot_container.getChildByName('Logo');
    this.logoTop = this.slot_container.getChildByName('LogoTop');
    this.freeSpinsTopContainer = this.slot_container.getChildByName('FreeSpinsTopContainer');
    this.respinButtonsContainer = this.slot_container.getChildByName('RespinButtonsContainer');
    this.respinInReelInfoContainer = this.slot_container.getChildByName('RespinInReelInfoContainer');
    this.reelsContainer = this.slot_container.getChildByName('ReelsContainer');
    this.stickySymbolsContainer = this.slot_container.getChildByName('StickySymbolsContainer');
    this.symbolsAnimationsContainer = this.slot_container.getChildByName('SymbolsAnimationsContainer');
    this.linesContainer = this.slot_container.getChildByName('LinesContainer');
    this.lineWinningContainer = this.slot_container.getChildByName('LineWinningContainer');

    this.backgroundContainer = this.getChildByName('BackgroundContainer');
    // this.bigWinContainer = this.getChildByName('BigWinContainer');
    this.freeSpinsWindowContainer = this.getChildByName('FreeSpinsWindowContainer');
    this.purchaseContainer = this.getChildByName('PurchaseContainer');
    //this.linesContainer.showLines(GameModel.lines);
  }

  playBGSound() {
    if (EntryPoint.GameModel.isFreeSpinsMode) return;
    SoundManager.play(eSounds.BACKGROUND.name, eSounds.BACKGROUND.volume, true, 'music');
  }

  addListeners() {
    GlobalDispatcher.add('model:linesChanged', this.onLinesChanged, this);
    GlobalDispatcher.add('stateManager.stateChanged', this.onStateChanged, this);
    GlobalDispatcher.add('stateEnd:initState', this.onInitStateEnd, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );

    // GlobalDispatcher.add('model:getServerData', this.checkForFreeSpins, this);
  }

  onLinesChanged(data) {
    //this.linesContainer.showLines(data.params);
  }

  onInitStateEnd() {
    this.playBGSound();
    if (!EntryPoint.GameModel.isFreeSpinsMode) return;

    if (!EntryPoint.mobile) {
      this.logoTop.visible = true;
    }
    this.freeSpinsTopContainer.show();
    this.backgroundContainer.showFreeSpinsBack();
    this.respinButtonsContainer.setActive(false);
    this.respinInReelInfoContainer.setActive(false);
    SoundManager.pause(eSounds.BACKGROUND.name);
    SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
  }

  onStateChanged(data) {
    switch (data.params) {
      case 'initState':

        break;
      case 'showStartScreenState':
        if (EntryPoint.GameModel.isFreeSpinsMode) {
          this.logo.showMultiplier();
          this.logoTop.visible = false;
        }
        break;
      case 'waitUserInteractionState':
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        this.logo.showMultiplier();
        if (!EntryPoint.mobile || this.currentOrientation === 'H') {
          this.logoTop.visible = false;
        }
        this.stickySymbolsContainer.enable();
        break;
      case 'idleState':
        if (this.freeSpinsWindowContainer.isVisible) {
          this.freeSpinsWindowContainer.hide();
        }
        if (!EntryPoint.GameModel.isFreeSpinsMode) {
          if (!EntryPoint.mobile || this.currentOrientation === 'H') {
            this.logoTop.goToFrame(2);
            this.logoTop.visible = true;
          }
          this.logo.hideMultiplier();
          this.stickySymbolsContainer.disable();
          this.freeSpinsTopContainer.hide();
          this.respinButtonsContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
          this.respinInReelInfoContainer.setActive(!Object.keys(EntryPoint.GameModel.freeSpins).length && EntryPoint.GameModel.respinEnabled && !EntryPoint.AutoPlaySettings.active);
        }
        this.respinButtonsContainer.setData(EntryPoint.GameModel.respinCost);
        this.respinInReelInfoContainer.setData(EntryPoint.GameModel.respinCost);
        break;
      case 'requestRespinRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.startRespin();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'stopRespinRollingState':
        this.reelsContainer.stopRespin(GameModel.reels);
        break;
      case 'requestRollingState':
        this.reelsContainer.hideWinningSymbols();
        this.reelsContainer.start();
        this.winningsController.reset();
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.reelsContainer.setActive(true);
        this.lineWinningContainer.hide();
        this.respinButtonsContainer.setActive(false);
        this.respinInReelInfoContainer.setActive(false);
        break;
      case 'stopRollingState':
        NearWinCalculator.calculate();
        this.reelsContainer.stop(GameModel.reels);
        break;
      case 'showBigWinState':
        this.lineWinningContainer.show(2);
        break;
      case 'showLinesWinState':
        this.lineWinningContainer.show(1);
        break;
      case 'showWinningsState':
        this.winningsController.start();
        this.reelsContainer.setActive(false);
        break;
      case 'expandSymbolsState':
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(false);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        break;
      case 'showExpandedWinState':
        SoundManager.play(eSounds.SMALL_WIN.name, eSounds.SMALL_WIN.volume, false, 'winning');
        break;
      case 'freeSpinsAddState':
        SoundManager.pause(eSounds.BACKGROUND.name);
        const freeSpinsSound = SoundManager.getSound(eSounds.FREE_SPINS_BACK.name);
        if (!freeSpinsSound.isPlaying) {
          SoundManager.play(eSounds.FREE_SPINS_BACK.name, eSounds.FREE_SPINS_BACK.volume, true, 'music');
        }
        this.stickySymbolsContainer.enable();
        this.freeSpinsWindowContainer.show(1);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.logo.showMultiplier();
        if (!EntryPoint.mobile || this.currentOrientation === 'H') {
          this.logoTop.visible = false;
        }
        this.freeSpinsTopContainer.show();
        this.backgroundContainer.showFreeSpinsBack();
        GlobalDispatcher.dispatch('ui:changeAutoPlay', true);
        break;
      case 'freeSpinsOutState':
        SoundManager.resume(eSounds.BACKGROUND.name);
        SoundManager.stop(eSounds.FREE_SPINS_BACK.name);
        this.stickySymbolsContainer.disable();
        this.freeSpinsWindowContainer.show(2);
        this.reelsContainer.hideWinningSymbols();
        this.winningsController.reset();
        this.symbolsAnimationsContainer.removeAnimations();
        this.lineWinningContainer.hide();
        this.reelsContainer.setActive(true);
        this.linesContainer.hideLines();
        this.linesContainer.reset();
        this.logo.hideMultiplier();
        if (!EntryPoint.mobile) {
          this.logoTop.visible = false;
        }
        this.freeSpinsTopContainer.hide();
        this.backgroundContainer.hideFreeSpinsBack();
        GlobalDispatcher.dispatch('ui:changeAutoPlay', false);
        break;
    }
  }

}

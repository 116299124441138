import EntryPoint from 'Engine/EntryPoint';
import BaseSlotSymbol from 'Engine/containers/BaseSlotSymbol';
import { gsap } from  'gsap'

export default class Symbol extends BaseSlotSymbol {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.createTweens();

    this.incativeTintColor = 0x444444;
  }

  init() {
    super.init();
    this.frame = this.getChildAt(0);
  }

  createTweens() {
    this.animationTween = gsap.to(this.scale, {x:0.9, y:0.9, yoyo:true, duration: 0.1, repeat:-1, paused:true});
    this.showTween = gsap.to(this, {alpha: 1, paused:true,duration: 0.1, onComplete: this.onShowCompete.bind(this)});
    this.fadeTween = gsap.to(this,  {alpha: 0, duration: 0.1, paused:true});
  }

  changeSymbol(id, reel) {
    if (EntryPoint.GameModel.freeSpinsInActiveState && id === 1) {
      id = 11
    }

    super.changeSymbol(id, reel);
    this.stopAnimation();
    // this.frame.id = this.id;
  }

  iterateOverSymbols(symbols) {
    this.iterateSymbols = symbols;
    this.alpha = 0;

    this.animateSymbolChange(this.iterateSymbols.pop());

    this.iterateInterval = setInterval(() => {
      this.animateSymbolChange(this.iterateSymbols.pop());

      if (!this.iterateSymbols.length) {
        this.clearInterval();
      }
    }, 200)
  }


  animateSymbolChange(symbol) {
    this.fadeTween.kill();
    this.showTween.kill();

    this.showTween.play(0);
    this.changeSymbol(symbol);
  }

  showFrame() {
    this.frame.goToFrame(2);
  }

  hideFrame() {
    this.frame.goToFrame(1);
  }

  startAnimation(min = false) {
    this.image = this.getChildAt(1);
    this.image.visible = false;
  }

  stopAnimation() {
    this.image.visible = true;
    this.frame.goToFrame(1);
    // this.frame.visible = true;
  }

  setBlur(value) {
    // super.setBlur(value);
    // this.frame.setBlur();
    /*if (value && this.id < 6) {
       this.setActive(EntryPoint.GameModel.isFreeSpinsMode && this.id === EntryPoint.GameModel.freeSpins.specialSymbol);
    } else {
        this.setActive(true);
    }*/
    this.setActive(true);
  }

  setActive(value) {
    // this.frame.setActive(value);
    let image = this.getChildAt(1);
    image.tint = value ? 16777215 : this.incativeTintColor;
  }
}

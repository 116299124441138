export default {
  SYMBOLS: 'symbols',
  BIG_WIN: 'bigWin_spine',
  ANTISIPATOR: 'neat_win',
  PURCHASE_BUTTON: 'purchase_button',
  PURCHASE: 'purchase_animation',
  FREE_SPINS_WINDOW: 'freeSpinsWindow_spine',
  LINES_SPINE: 'lines_spine',
  COINS: 'coins',
  MULTIPLIER: 'multiplier',
  SUN: 'sun'
}

import FlashLib from "flashlib_onlyplay";

export default class FreeSpinsCountContainer extends FlashLib.MovieClip {
    constructor(data, displayItemData) {
        super(data, displayItemData);

        this.valueText = null;

        this.init();
    }

    init() {
        this.titleText = this.getChildByName('ui_freeSpins_label');
        this.valueText = this.getChildByName('valueText');
    }

    setValue(value) {
        this.valueText.text = value;
    }
}

import FlashLib from 'flashlib_onlyplay';
import EntryPoint from '../libs/game-engine/src/EntryPoint';
import GlobalDispatcher from '../libs/game-engine/src/events/GlobalDispatcher';
import animationCreator from 'Engine/animations/animationCreator';
import * as PIXI from 'pixi.js';

export default class StickySymbolsContainer extends FlashLib.MovieClip {
  constructor(data, displayData) {
    super(data, displayData);

    this.positions = [];
    this.positionsToAnimate = [];
    this.listeners = {};
    this.animations = {};
    this.enabled = false;
    this.showWinningsComplete = true;

    // let mask = new PIXI.Graphics();
    // mask.beginFill(0x0, 1);
    // mask.drawRect(-139, -115, (267 + 5) * 5, 226 * 3);
    // mask.endFill();
    // this.addChild(mask);
    // this.mask = mask;

    this.init();

    this.checkStickySymbols = this.checkStickySymbols.bind(this);
    this.showStickySymbols = this.showStickySymbols.bind(this);
    this.onSymbolAnimationStart = this.onSymbolAnimationStart.bind(this);
    this.onSymbolAnimationComplete = this.onSymbolAnimationComplete.bind(this);
    this.showWinningSymbols = this.showWinningSymbols.bind(this);
    this.onWinningsComplete = this.onWinningsComplete.bind(this);
    this.onWinningsStart = this.onWinningsStart.bind(this);
    this.onInitStateEnd = this.onInitStateEnd.bind(this);

    GlobalDispatcher.addOnce('stateEnd:initState', this.onInitStateEnd);
  }

  init() {
    for (let i = 0; i < EntryPoint.configData.reels.reelsCount; i++) {
      for (let j = 0; j < EntryPoint.configData.reels.symbolsInReel; j++) {
        const key = `place_${i}_${j}`;
        this[key] = this.getChildByName(key);
        this[key].visible = false;
        this.positions.push(this[key]);
      }
    }
  }

  enable() {
    if (this.enabled) return;

    GlobalDispatcher.add('stateStart:stopRollingState', this.checkStickySymbols);
    GlobalDispatcher.add('reel:stopped', this.showStickySymbols);
    GlobalDispatcher.add('symbolAnimation:start', this.onSymbolAnimationStart);
    GlobalDispatcher.add('symbolAnimation:complete', this.onSymbolAnimationComplete);
    GlobalDispatcher.add('symbolAnimation:end', this.onSymbolAnimationComplete);
    GlobalDispatcher.add('winningsController:slowWin', this.showWinningSymbols);
    GlobalDispatcher.add('winningsController:startShowWinnings', this.onWinningsStart);
    GlobalDispatcher.add('winningsController:allWinningsComplete', this.onWinningsComplete);
    this.enabled = true;
  }

  disable() {
    if (!this.enabled) return;

    this.hideSymbols();
    GlobalDispatcher.remove('stateStart:stopRollingState', this.checkStickySymbols);
    GlobalDispatcher.remove('reel:stopped', this.showStickySymbols);
    GlobalDispatcher.remove('symbolAnimation:start', this.onSymbolAnimationStart);
    GlobalDispatcher.remove('symbolAnimation:complete', this.onSymbolAnimationComplete);
    GlobalDispatcher.remove('symbolAnimation:end', this.onSymbolAnimationComplete);
    GlobalDispatcher.remove('winningsController:slowWin', this.showWinningSymbols);
    GlobalDispatcher.remove('winningsController:startShowWinnings', this.onWinningsStart);
    GlobalDispatcher.remove('winningsController:allWinningsComplete', this.onWinningsComplete);
    this.enabled = false;
  }

  onInitStateEnd() {
    if (!EntryPoint.GameModel.isFreeSpinsMode) return;
    this.checkStickySymbols();
    for (let i = 0; i < EntryPoint.GameModel.reels.length; i++) {
      this.showStickySymbols({ params: i });
    }
  }

  onSymbolAnimationStart(event) {
    let name = event.params.name;
    if (name !== 'book') return;

    let position = event.params.position;
    this[`place_${position[0]}_${position[1]}`].visible = false;
  }

  onSymbolAnimationComplete(event) {
    let position = event.params;
    const key = `place_${position[0]}_${position[1]}`;
    if (this.animations[key]) {
      this[key].visible = true;
    }
  }

  hideSymbols() {
    Object.values(this.animations).forEach(animation => {
      animation.destroy({ children: true });
    });

    this.animations = {};
    this.positions.forEach(position => position.visible = false);
    this.positionsToAnimate = [];
  }

  checkStickySymbols(event) {
    const stickyPositions = EntryPoint.GameModel.freeSpins.stickyPositions;
    for (let id = 0; id <= 4; id++) {
      const stickyPositionsOnReel = stickyPositions.filter(position => position[0] === id);
      stickyPositionsOnReel.forEach(position => {
        if (!this[`place_${position[0]}_${position[1]}`].visible) {
          this.positionsToAnimate.push(position);
        }
      });
    }
  }

  showStickySymbols(event) {
    const id = event.params;
    this.positionsToAnimate.forEach((position, i) => {
      const key = `place_${position[0]}_${position[1]}`;

      if (position[0] === id && !this[key].visible) {
        const animation = animationCreator.createAnimation('symbols');
        animation.zIndex = 1;
        const place = this[key];
        const listener = {
          complete: () => {
            animation.state.removeListener(listener);
            animation.state.setAnimation(0, 'sun_idle', true)
          }
        };

        animation.state.setAnimation(0, 'sun_stop', false);
        animation.state.addListener(listener);
        place.visible = true;
        place.addChild(animation);

        this.animations[key] = animation;
      }
    });

    if (id === 4) {
      this.positionsToAnimate = [];
    }
  }

  showWinningSymbols(data) {
    if (this.showWinningsComplete) return;
    data.params.path.forEach((pathData) => {
      const key = `place_${pathData[0]}_${pathData[1]}`;
      const animation = this.animations[key];

      if (!animation) return;

      this.listeners[key] = {
        complete: () => {
          animation.state.removeListener(this.listeners[key]);
          animation.state.setAnimation(0, 'sun_idle', true)
        }
      };

      animation.state.clearTrack(0);
      animation.skeleton.setToSetupPose();
      animation.state.setAnimation(0, 'sun_stop', false);
      animation.state.addListener(this.listeners[key]);
    });
  }

  onWinningsStart() {
    this.showWinningsComplete = false;
  }

  onWinningsComplete() {
    this.showWinningsComplete = true;
    Object.keys(this.animations).forEach(key => {
      this.animations[key].state.removeListener(this.listeners[key]);
      this.animations[key].state.setAnimation(0, 'sun_idle', true)
    });
    this.listeners = {};
  }
}

import FlashLib from "flashlib_onlyplay";

import BaseButton from "Engine/base/ui/BaseButton";
import BaseCheckBox from "Engine/base/ui/BaseCheckBox";

import Main from "./Main";

import BackgroundContainer from "./background/BackgroundContainer";
import LogoContainer from "./logo/LogoContainer";
import SlotFrameContainer from './slotFrame/slotFrameContainer';

import ReelsContainer from "./reels/ReelsContainer";
import StickySymbolsContainer from './reels/StickySymbolsContainer';

import Symbol from "./symbols/Symbol";
import SymbolsFrames from "./symbols/SymbolsFrames";

import LinesContainer from "./lines/SlotLinesContainer";
import WinLineAnimated from "./lines/WinLineAnimated";

import SymbolsAnimationsContainer from "./symbolsAnimations/SymbolsAnimationsContainer";

import LineWinningContainer from "./lineWin/LineWinningContainer";

import FreeSpinsWindowContainer from "./freeSpins/FreeSpinsWindowContainer";

import OverlayContainer from "Engine/containers/overlay/BaseOverlayContainer";

import RespinButtonsContainer from "./respin/RespinButtonsContainer";
import RespinButton from "./respin/RespinButton";
import RespinInReelInfo from "./respin/RespinInReelInfo";
import RespinInReelInfoContainer from "./respin/RespinInReelInfoContainer";

import SettingsContainer from "Engine/containers/setting/BaseSettingsContainer";

import StartScreenContainer from './startScreen/startScreen';
import StartScreenContent from './startScreen/startScreenContent';

import ExpandingSymbolsContainer from "./expandingSymbols/ExpandingSymbolsContainer";

import FreeSpinsTopContainer from "./freeSpinsTop/FreeSpinsTopContainer";
import FreeSpinsCountContainer from "./freeSpinsTop/FreeSpinsCountContainer";
import FreeSpinsTotalWinContainer from "./freeSpinsTop/FreeSpinsTotalWinContainer";

import UiContainer from "./ui/BaseGameUiContainer";
import UiCoinValueContainer from "Engine/containers/ui/BaseCoinValueContainer";

import PurchaseContainer from './bonusPurchase/popupBonusPurchase';
import PurchaseCard from './bonusPurchase/ControllerBonusPurchaseCard';
import PurchaseConfirm from './bonusPurchase/purchaseConfirm';

import ButtonAnimated from './ui/ButtonAnimated';

import UiMobileContainer from "./ui/BaseGameUiContainer";

import GameInfoContainer from "./gameInfo/GameInfoContainer";
import GameInfoContent from "./gameInfo/GameInfoContent";
import GameInfoControls from "./gameInfo/GameInfoControls";

import FreeSpinMultiplierContainer from './freeSpinsTop/FreeSpinsCountContainer';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import PopupWin from './popups/popupWin/PopupWin';
import InfoPopupContainer from 'Engine/containers/InfoPopupContainer';
import ControllerAntisipators from 'Engine/base/antisipators/ControllerAntisipators';

FlashLib.registerClass('BaseButton', BaseButton);
FlashLib.registerClass('BaseCheckBox', BaseCheckBox);

FlashLib.registerClass('Main', Main);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('BackgroundMobileContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('StickySymbolsContainer', StickySymbolsContainer);
FlashLib.registerClass('Symbol', Symbol);
FlashLib.registerClass('SymbolsFrames', SymbolsFrames);
FlashLib.registerClass('WinLineAnimated', WinLineAnimated);
FlashLib.registerClass('LinesContainer', LinesContainer);
FlashLib.registerClass('SymbolsAnimationsContainer', SymbolsAnimationsContainer);
FlashLib.registerClass('LineWinningContainer', LineWinningContainer);
FlashLib.registerClass('FreeSpinsWindowContainer', FreeSpinsWindowContainer);
FlashLib.registerClass('LogoContainer', LogoContainer);
FlashLib.registerClass('AntisipatorsController', ControllerAntisipators);
FlashLib.registerClass('SlotFrameContainer', SlotFrameContainer);

FlashLib.registerClass('OverlayContainer', OverlayContainer);

FlashLib.registerClass('RespinButtonsContainer', RespinButtonsContainer);
FlashLib.registerClass('RespinButton', RespinButton);
FlashLib.registerClass('RespinInReelInfo', RespinInReelInfo);
FlashLib.registerClass('RespinInReelInfoContainer', RespinInReelInfoContainer);

FlashLib.registerClass('SettingsContainer', SettingsContainer);

FlashLib.registerClass('StartScreenContainer', StartScreenContainer);
FlashLib.registerClass('StartScreenContent', StartScreenContent);

FlashLib.registerClass('ExpandingSymbolsContainer', ExpandingSymbolsContainer);

FlashLib.registerClass('FreeSpinsTopContainer', FreeSpinsTopContainer);
FlashLib.registerClass('FreeSpinsCountContainer', FreeSpinsCountContainer);
FlashLib.registerClass('FreeSpinsTotalWinContainer', FreeSpinsTotalWinContainer);

FlashLib.registerClass('UiContainer', UiContainer);
FlashLib.registerClass('UiCoinValueContainer', UiCoinValueContainer);
FlashLib.registerClass('PurchaseContainer', PurchaseContainer);
FlashLib.registerClass('PurchaseCard', PurchaseCard);
FlashLib.registerClass('PurchaseConfirm', PurchaseConfirm);

FlashLib.registerClass('ButtonAnimated', ButtonAnimated);

FlashLib.registerClass('UiMobileContainer', UiMobileContainer);

FlashLib.registerClass('InfoPopupContainer', InfoPopupContainer);

FlashLib.registerClass('PopupWin', PopupWin);

FlashLib.registerClass('GameInfoContainer', GameInfoContainer);
FlashLib.registerClass('GameInfoContent', GameInfoContent);
FlashLib.registerClass('GameInfoControls', GameInfoControls);

FlashLib.registerClass('FreeSpinMultiplierContainer', FreeSpinMultiplierContainer);

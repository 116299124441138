import BaseState from "Engine/base/states/BaseState";
import EntryPoint from "Engine/EntryPoint";

export default class FreeSpinsInState extends BaseState {

    constructor(stateData) {
        super(stateData);

        this.timeout = null;
    }

    async start() {
        OPWrapperService.infoPanel.setGameState(OPWrapperService.infoPanel.gameStateTypes.EGST_FREESPINS_GAME);
        EntryPoint.GameModel.isFreeSpinsInActiveState = true;
        if (!PIXI.Assets.cache.has('multiplier') || !PIXI.Assets.cache.has('sun')) {
            window.OPPreloader.showDelayedPreloader();
            await PIXI.Assets.load('multiplier');
            await PIXI.Assets.load('sun');
            window.OPPreloader.hideDelayedPreloader();
        }
        super.start();
        EntryPoint.AutoPlaySettings.active = false;
        this.timeout = setTimeout(() => {
            this.complete();
        }, 5000);
    }

    onEnd() {
        super.onEnd();
        clearTimeout(this.timeout);
    }
}

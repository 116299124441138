import GameModel from "../model/GameModel";
import EntryPoint from 'Engine/EntryPoint';

export default new class XXXDataParser {
    constructor() {
        this.sessionToken = null;
        this.symbolsRelation = {
            1000: 10,
            1001: 1,
            1002: 2,
            1003: 3,
            1004: 4,
            1005: 5,
            1006: 6,
            1007: 7,
            1008: 8,
            1009: 9
        };
    }

    parseInit(data) {
        this.sessionToken = data.session_token;
        let newData = {};
        newData.action = 'init';
        newData.balance = data.balance;
        newData.params = {
            linesPossible: [10],
            betPossible: data.bets_available
        };
        newData.current = {
            betIndex: data.bet_index,
            lines: 10,
            bet:  data.bet_amount
        };
        newData.spin = {
            reels: this.slotGridToReels(data.slot_grid),
            winnings: null,
            stopIndexes: this.getStopIndexes(data.slot_grid)
        };
        newData.respinCost = data.respin_cost;
        newData.special = this.makeSpecial(data);
        newData.freeSpins = this.makeFreeSpins(data);
        newData.platformData = {
            historyUrl: data.history_url,
            lobbyUrl: data.lobby_url
        };

        newData.currencyInfo = {
            decimals: data.currency_decimals,
            symbol: data.currency_symbol,
        };

        newData.paytableInfo = data.paytable;
        newData.country = data.country;
        newData.bonusCostMultiplier = data.bonus_buy_multiplier;
        newData.freebets = data.freebets;

        return newData;
    }

    parseSpin(data) {
        if(data.status && data.status === 911) {
            let newData = {};
            newData.action = 'spin';
            newData.realityCheck = data;
            newData.spin = {
                reels: GameModel.reels,
                stopIndexes: GameModel.stopIndexes
            }
            return newData;
        }

        let newData = {};
        newData.action = 'spin';
        newData.balance = data.balance;

        newData.spin = {
            reels: this.slotGridToReels(data.slot_grid),
            winnings: this.makeWinnings(data),
            stopIndexes: this.getStopIndexes(data.slot_grid)
        };
        newData.special = this.makeSpecial(data);
        newData.freeSpins = this.makeFreeSpins(data);
        newData.respinCost = data.respin_cost;

        newData.freebets = data.freebets;

        if (data.hasOwnProperty('bet_index')) newData.betIndex = data.bet_index;

        return newData;
    }

    parseRespin(data) {
        let newData = {};
        newData.action = 'respin';
        newData.balance = data.balance;
        newData.spin = {
            reels: this.slotGridToReels(data.slot_grid),
            winnings: this.makeWinnings(data),
            stopIndexes: this.getStopIndexes(data.slot_grid)
        };
        newData.respinCost = data.respin_cost;
        newData.respinReelIndex = GameModel.respinReelIndex;
        newData.freeSpins = this.makeFreeSpins(data);

        newData.freebets = data.freebets;

        if (data.hasOwnProperty('bet_index')) newData.betIndex = data.bet_index;

        return newData;
    }

    parsePurchaseBonusGame(data) {
        let newData = {};
        newData.action = 'buy_bonus';
        newData.balance = data.balance;
        newData.special = this.makeSpecial(data);
        newData.freeSpins = this.makeFreeSpins(data);

        newData.freebets = data.freebets;

        if (data.hasOwnProperty('bet_index')) newData.betIndex = data.bet_index;

        return newData;
    }

    slotGridToReels(gridData) {
        let reels = [];
        gridData.forEach((gridReel)=>{
            let reel = [];
            gridReel.forEach((gridSymbol)=>{
                reel.push(this.symbolsRelation[gridSymbol[1].id]);
            });
            reels.push(reel);
        });
        return reels;
    }

    makeWinnings(data) {
        let winnings = {};
        winnings.totalWin = data.win_currency;

        if (data.winning_combinations && data.winning_combinations.length > 0) {
            winnings.lines = [];
            data.winning_combinations.forEach((winData)=>{
                let line = {};
                line.symbol = this.symbolsRelation[winData.payout.symbols[0].id];
                line.path = winData.payline.line.concat().splice(0, winData.payout.length);
                // line.line = this.linesRelation[winData.payline.number];
                line.line = winData.payline.number;
                line.count = winData.payout.length;
                line.win = winData.win_currency;

                winnings.lines.push(line);
            });
        }
        if (data.bonus && data.bonus.freebets_position && data.bonus.freebets_position.length > 0) {
            winnings.scatters = [];

            let scatter = {};
            scatter.symbol = 1;
            scatter.path = data.bonus.freebets_position;
            scatter.count = data.bonus.freebets_position.length;
            scatter.win = data.bonus.bonus_win_currency;

            winnings.scatters.push(scatter);
        }

        return winnings;
    }

    makeFreeSpins(data) {
        if (!data.freebet) {
            return null;
        }

        let bonusSymbols = this.symbolsRelation[data.bonus.game_bonuses.freebet_extra_payout.active_symbols[0].id];
        let freeSpinsData = {
            total: data.freebet.total_amount_given,
            played: data.freebet.total_amount_given - data.freebet.amount_left,
            left: data.freebet.amount_left,
            added: data.freebet.amount_given,
            specialSymbol: bonusSymbols,
            totalWin: data.freebet.total_win,
            stickyPositions: data.freebet.sticky_positions,
            multiplier: data.freebet.sticky_multiplier,
        };
        return freeSpinsData;
    }

    makeSpecial(data) {
        if(!data.bonus || !data.bonus.bonus_grids || data.bonus.bonus_grids.length === 0) {
            return null;
        }
        let specialData = {
            symbol: this.symbolsRelation[data.bonus.bonus_grids[0].payout_symbol.id],
            positions: data.bonus.bonus_grids[0].symbols_position,
            win: data.bonus.bonus_grids[0].payout_currency
        };
        return specialData;
    }

    getStopIndexes(data) {
        const result = [];
        for(let reelData of data){
            result.push(reelData[2][0]);
        }
        return result;
    }
}

import en from '../assets/localization/en';
import enSocial from '../assets/localization/en-social';
import SoundManager from 'Engine/soundManager/SoundManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import { eSounds } from './enums/eSounds';

const onSettingsClick = () => {
  SoundManager.play(eSounds.EST_BUTTON_CLICK.name);
  GlobalDispatcher.dispatch('ui:settingsClick');
};

export default {
  buttons: [
    {
      type: 'settings',
      onClick: onSettingsClick,
      styles: ['settings']
    },
  ],
  bundle: 'bookofeye',
  localizationBundle: 'bookofeye',
  localizations: { en, 'en-social': enSocial },
  lang: 'en',
  fallbackLang: 'en'
};
